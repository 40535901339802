import { createRouter, createWebHistory } from 'vue-router'

import Home from './views/Home.vue'
import Listagem from './views/Listagem.vue'
import Imovel from './views/Imovel.vue'
import Sobre from './views/Sobre.vue'
import Contato from './views/Contato.vue'
import Politica from './views/Politica.vue'
import NotFound from './views/404.vue'

const routes = [
    { name: 'home', path: '/', component: Home },
    { name: 'imoveis', path: '/imoveis', component: Listagem, base: '/', props: true},
    { name: 'imoveistemporada', path: '/imoveis/temporada', component: Listagem, base: '/', props: { pfinalidade: 'Temporada' }},
    { name: 'imoveisanual', path: '/imoveis/anual', component: Listagem, base: '/', props: { pfinalidade: 'Anual' }},
    { name: 'imoveiscomprar', path: '/imoveis/comprar', component: Listagem, base: '/', props: { pfinalidade: 'Vender' }},
/*    
    { name: 'temporada', path: '/imoveis', component: Listagem, base: '/', props: { pfinalidade: 'Temporada' }},
    { name: 'mensal', path: '/imoveis', component: Listagem, base: '/', props: { pfinalidade: 'Mensal' }},
    { name: 'compra', path: '/imoveis', component: Listagem, base: '/', props: { pfinalidade: 'Vender' }},
*/
    { name: 'imoveiscategoria', path: '/imoveis', component: Listagem, base: '/', props: true },
    { name: 'imovel', path: '/imovel/:id', component: Imovel, base: '/'},
    { name: 'sobre', path: '/sobre', component: Sobre },
    { name: 'contato', path: '/contato', component: Contato },
    { name: 'politicadeprivacidade', path: '/politica-de-privacidade', component: Politica },
    { name: '404', path: '/:pathMatch(.*)*', component: NotFound },
]

export const router = createRouter({
    history:createWebHistory(),
    routes
})
