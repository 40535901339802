<template>
    <nav class="navbar navbar-expand-xl bg-transparent px-0 w-100 w-xl-auto">
        <router-link to="/" class="navbar-brand mr-7">
            <div v-show="currentRouteName == 'home'">
                <img v-if="!localIsMobile()" src="/../images/logo.png" alt="Imobiliária Santos" class="normal-logo">
                <img v-if="localIsMobile()" src="/../images/logo-white.png" alt="Imobiliária Santos" class="normal-logo">
                <img src="/../images/logo.png" alt="Imobiliária Santos" class="sticky-logo">
            </div>
            <div v-show="currentRouteName != 'home'">
                <img v-if="!localIsMobile()" src="/../images/logo.png" alt="Imobiliária Santos" class="normal-logo">
                <img v-if="!localIsMobile()" src="/../images/logo.png" alt="Imobiliária Santos" class="sticky-logo">
                <img v-if="localIsMobile()" src="/../images/logo-white.png" alt="Imobiliária Santos" class="normal-logo">
                <img v-if="localIsMobile()" src="/../images/logo-white.png" alt="Imobiliária Santos" class="sticky-logo">
            </div>
        </router-link>
        <div id="creci" class="d-xl-block font-weight-bold fs-15 mb-n6">CRECI 4014-J</div>
        <div id="crecim" class="fixed-top text-right text-white font-weight-bold fs-13 pr-3">CRECI 4014-J</div>
        <button class="navbar-toggler border-0 px-0" type="button" @click="toggleMobileMenu()" aria-controls="primaryMenu02" aria-expanded="false" aria-label="Toggle navigation">
            <span class="text-white fs-24"><i class="fal fa-bars"></i></span>
        </button>
        <div :class="{collapse: !mobileMenu.open}" class=" navbar-collapse mt-3 mt-xl-0" id="primaryMenu02">
            <ul class="navbar-nav hover-menu main-menu px-0 mx-xl-n4">
                <li id="navbar-item-home" aria-haspopup="true" class="nav-item dropdown py-2 py-xl-5 px-0 px-xl-4">
                    <router-link to="/" class="nav-link p-0">Home
                        <span class="caret"></span>
                    </router-link>
                </li>
                <li id="navbar-item-rent" aria-haspopup="true" class="nav-item dropdown py-2 py-xl-5 px-0 px-xl-4" @click="toggleMobileMenu()">
                    <router-link :to="{ name: 'imoveistemporada'}" class="nav-link p-0">Alugar Temporada
                        <span class="caret"></span>
                    </router-link>
                </li>
                <li id="navbar-item-rent" aria-haspopup="true" class="nav-item dropdown py-2 py-xl-5 px-0 px-xl-4" @click="toggleMobileMenu()">
                    <router-link :to="{ name: 'imoveisanual'}" class="nav-link p-0">Alugar Anual
                        <span class="caret"></span>
                    </router-link>
                </li>
                <li id="navbar-item-buy" aria-haspopup="true" class="nav-item dropdown py-2 py-xl-5 px-0 px-xl-4" @click="toggleMobileMenu()">
                    <router-link :to="{ name: 'imoveiscomprar'}" class="nav-link p-0">Comprar
                        <span class="caret"></span>
                    </router-link>
                </li>
                <li id="navbar-item-about" aria-haspopup="true" class="nav-item dropdown py-2 py-xl-5 px-0 px-xl-4">
                    <router-link to="/sobre" class="nav-link p-0">Sobre Nós
                        <span class="caret"></span>
                    </router-link>
                </li>
                <li id="navbar-item-contact" aria-haspopup="true" class="nav-item dropdown py-2 py-xl-5 px-0 px-xl-4">
                    <router-link to="/contato" class="nav-link p-0">Contato
                        <span class="caret"></span>
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    name: "mobilemenu",
    data() {
        return {
            mobileMenu: {
                open: false
            }
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        }
    },
    methods: {
        toggleMobileMenu() {
            this.mobileMenu.open = !this.mobileMenu.open;
        },
        localIsMobile() {
            return window.matchMedia('(max-width: 1180px)').matches;
        }
    }
}
</script>