<template>
  <div class="card border-0 hover-change-image">
    <div class="bg-hover-overlay rounded-lg card-img">
      <router-link :to="{ name: 'imovel', params: { id: imovel.id } }" class="text-dark hover-primary">
      <img
        :src="baseUrl + imovel.fotos[0]"
        :alt="imovel.nome"
        class="cropped"
      />
      <div class="card-img-overlay p-2 d-flex flex-column">
        <div v-if="imovel.operacao === 'Alugar'">
          <span v-if="imovel.aluguel === 'Temporada'"
            class="badge badge-primary"
            >Aluguel Temporada</span
          >
          <span v-if="imovel.aluguel === 'Anual'"
            class="badge badge-warning"
            >Aluguel Anual</span
          >
        </div>
        <div v-else>
          <span
            class="badge badge-secondary"
            v-if="imovel.operacao === 'Vender'"
            >Venda</span
          >
        </div>
        <div v-if="imovel.status === 'Reservado'">
          <span class="badge badge-danger text-dark fs-16 mt-1">RESERVADO</span>
        </div>

        <div class="mt-auto d-flex hover-image">
          <ul class="list-inline mb-0 d-flex align-items-end mr-auto">
            <li
              class="list-inline-item mr-2"
              data-toggle="tooltip"
              :title="imovel.fotos.length + ' Imagens'"
            >
              <a href="#" class="text-white hover-primary">
                <i class="far fa-images"></i
                ><span class="pl-1">{{ imovel.fotos.length }}</span>
              </a>
            </li>
            <li class="list-inline-item" data-toggle="tooltip" title="Vídeo" v-if="imovel.url_video.length > 0">
              <a href="#" class="text-white hover-primary">
                <i class="far fa-play-circle"></i><span class="pl-1">1</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      </router-link>
    </div>
    <div class="card-body pt-3 px-0 pb-1">
      <h2 class="fs-16 mb-1">
        <router-link
          :to="{ name: 'imovel', params: { id: imovel.id } }"
          class="text-dark hover-primary"
          >{{ imovel.nome }}</router-link
        >
      </h2>

      <h3 class="fs-12 mb-1 text-secondary">REFERÊNCIA {{ imovel.id }}</h3>
      <p class="font-weight-500 text-gray-light mb-1">{{ imovel.cidade }}</p>

      <div v-if="inArray('Vender', imovel.operacao) && (finalidade == 'Vender' || finalidade == 'Todas' || finalidade == '')">
        <p class="fs-17 font-weight-bold text-heading mb-1">
          <span class="list-inline-item badge badge-secondary mr-3 pt-2 pb-2" v-if="finalidade == 'Todas' || finalidade == ''">Venda</span>
          {{imovel.valor.toLocaleString("pt-br", { style: "currency", currency: "BRL", }) }}
        </p>
      </div>
      <div v-if="inArray('Alugar', imovel.operacao) && (finalidade == 'Alugar' || finalidade == 'Todas' || finalidade == '' || finalidade == 'Temporada' || finalidade == 'Anual')">
        <p class="fs-17 font-weight-bold text-primary mb-1" v-if="inArray('Alugar', imovel.operacao) && inArray('Temporada', imovel.aluguel) && (finalidade == 'Alugar' || finalidade == 'Temporada' || finalidade == 'Todas' || finalidade == '')">
          <span class="list-inline-item badge badge-primary mr-2 pt-2 pb-2" v-if="finalidade == 'Todas' || finalidade == ''">Aluguel Temporada</span>
          CONSULTE
        </p>
        <p class="fs-17 font-weight-bold text-heading mb-1" v-if="inArray('Alugar', imovel.operacao) && inArray('Mensal', imovel.aluguel) && (finalidade == 'Alugar' || finalidade == 'Anual' || finalidade == 'Todas' || finalidade == '')">
          <span class="list-inline-item badge badge-warning mr-2 pt-2 pb-2" v-if="finalidade == 'Todas' || finalidade == ''">Aluguel Anual</span>
          <span v-if="imovel.valor_aluguel > 0">
            {{imovel.valor_aluguel.toLocaleString("pt-br", { style: "currency", currency: "BRL", }) }}
          </span>
          <span v-else class="fs-17 font-weight-bold text-primary mb-1">
            CONSULTE
          </span>
        </p>
      </div>

      <ul class="list-inline mb-0">
        <li
          class="list-inline-item text-gray font-weight-500 fs-13 mr-sm-4"
          data-toggle="tooltip"
          :title="imovel.qtd_quartos + ' Quartos'"
          v-if="imovel.qtd_quartos>0"
        >
          <svg class="icon icon-bedroom fs-18 text-primary mr-1">
            <use xlink:href="#icon-bedroom"></use>
          </svg>
          {{ imovel.qtd_quartos }}
        </li>
        <li
          class="list-inline-item text-gray font-weight-500 fs-13 mr-sm-4"
          data-toggle="tooltip"
          :title="imovel.qtd_banheiros + ' Banheiros'"
          v-if="imovel.qtd_banheiros>0" 
        >
          <svg class="icon icon-shower fs-18 text-primary mr-1">
            <use xlink:href="#icon-shower"></use>
          </svg>
          {{ imovel.qtd_banheiros }}
        </li>
        <li
          class="list-inline-item text-gray font-weight-500 fs-13 mr-sm-4"
          data-toggle="tooltip"
          title="Área"
          v-if="parseInt(imovel.area_total) > 0"
        >
          <svg class="icon icon-square fs-18 text-primary mr-1">
            <use xlink:href="#icon-square"></use>
          </svg>
          {{ parseInt(imovel.area_total) }} m<sup>2</sup>
        </li>
        <li
          class="list-inline-item text-gray font-weight-500 fs-13"
          data-toggle="tooltip"
          :title="parseInt(imovel.vagas_garagem) + ' Garagem'"
          v-if="parseInt(imovel.vagas_garagem) > 0"
        >
          <svg class="icon icon-Garage fs-18 text-primary mr-1">
            <use xlink:href="#icon-Garage"></use>
          </svg>
          {{ parseInt(imovel.vagas_garagem) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "imovelcard",
  props: { 
    imovel: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    finalidade: {
      type: String,
      required: true,
    }
  },
  methods: {
    inArray(needle, haystack) {
        var length = haystack.length;
        for(var i = 0; i < length; i++) {
            if(haystack[i] == needle) return true;
        }
        return false;
    },
    aluguelTemporada(imovel) {
        if (![null, 'Temporada'].includes(imovel.aluguel) && imovel.valor > 0) return true
    }
  }
};
</script>

<style>
.cropped {
  width: 358px !important;
  height: 358px;
  object-fit: cover;
}
</style>