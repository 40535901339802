<template>
    <header-layout />

    <main id="content">
      <section class="pt-9 pb-10">
        <div class="container">
          <div class="text-center mb-15">
            <img :src="baseUrl +'images/page-404.jpg'" alt="Page 404" class="mb-5">
            <h1 class="fs-30 lh-16 text-dark font-weight-600 mb-5">Esta página não existe.</h1>
          </div>
        </div>
      </section>
    </main>

    <footer-layout />

    <svg-files />

</template>

<script>
import HeaderLayout from '@/layouts/Header.vue'
import FooterLayout from '@/layouts/Footer.vue'
import SvgFiles from '@/layouts/Svg.vue'

import { baseUrl } from '@/config.js'

export default {
  name: '404',
  components: {
    HeaderLayout,
    FooterLayout,
    SvgFiles
  },
  data() {
    return {
        baseUrl: baseUrl
    }
  }
};
</script>