<template>
    <footer class="bg-dark pt-8 pb-6 footer text-muted">
      <div class="container container-xxl">
        <div class="row">
          <div class="col-md-6 col-lg-6 mb-4 mb-md-0">
            <router-link to="/" class="d-block">
              <img src="/../images/logo-white.png" alt="Imobiliária Santos">
            </router-link>
            <div class="lh-20 font-weight-500">
              <p class="d-block text-lighter font-weight-bold fs-15 hover-white mb-0">CRECI 4014-J</p>
              <p class="mb-0fs-16">Rua 51 n.51 - Sala 02 - Centro<br/>Balneário Camboriú - SC<br/>CEP 88330-675</p>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 mb-6 mb-md-0 text-md-right">
            <a class="d-block text-lighter font-weight-bold fs-15 hover-white" style="letter-spacing: 4px;" href="tel:(47)33670826">(47) 3367-0826</a>
            <a class="d-block text-lighter font-weight-bold fs-15 hover-white" style="letter-spacing: 4px;" href="tel:(47)33670139">(47) 3367-0139</a>
            <div class="flex-default mt-2" id="whatsapp"><a class="d-block text-lighter font-weight-bold fs-15 hover-white" href="https://api.whatsapp.com/send?phone=5547988012756&text=Ol%C3%A1%2C%20quero%20mais%20informa%C3%A7%C3%B5es!" target="_blank" title="Vamos conversar no Whatsapp!">(47) 98801-2756 <img src="/../images/wpp-logo.svg" alt="Whatsapp" class="img mb-1 shake"></a></div>
            <div class="flex-default mt-2" id="whatsapp"><a class="d-block text-lighter font-weight-bold fs-15 hover-white" href="https://api.whatsapp.com/send?phone=5547988012029&text=Ol%C3%A1%2C%20quero%20mais%20informa%C3%A7%C3%B5es!" target="_blank" title="Vamos conversar no Whatsapp!">(47) 98801-2029 <img src="/../images/wpp-logo.svg" alt="Whatsapp" class="img shake"></a></div>
          </div>
        </div>
        <div class="mt-0 mt-md-5 row">
          <ul class="list-inline mb-0 col-md-6 mr-auto">
            <li class="list-inline-item mr-6">
                <router-link to="/politica-de-privacidade" class="text-muted lh-26 font-weight-500 hover-white">
                  Política de Privacidade
                </router-link>
            </li>
          </ul>
          <p class="col-md-auto mb-0 text-muted">
            © 2021 Imobiliária S.Santos. Todos os direitos reservados.
          </p>
        </div>
      </div>
    </footer>

    <iagree-layout />

    <div class="position-fixed pos-fixed-bottom-right pb-6 pr-6 z-index-10">
      <a href="#" class="gtf-back-to-top bg-white text-primary hover-white bg-hover-primary shadow p-0 w-52px h-52 rounded-circle fs-20 d-flex align-items-center justify-content-center" title="Voltar ao Topo"><i class="fal fa-arrow-up"></i></a>
    </div>

</template>

<script>

import IagreeLayout from '@/layouts/Iagree.vue'

export default {
  name: 'rodape',
  components: {
    IagreeLayout
  }
};
</script>