<template>
    <header-layout />

    <main id="content">

      <section class="pt-2 pb-10 pb-lg-17 page-title bg-overlay bg-img-cover-center" style="background-image: url('images/BG3.jpg');">
        <div class="container">
          <h1 class="fs-22 fs-md-42 mb-0 text-white font-weight-normal text-center pt-17 pb-13 lh-15 px-lg-16" data-animate="fadeInDown">
            Política de Privacidade
          </h1>
        </div>
      </section>
      <section class="bg-patten-05 mb-13">
        <div class="container">
          <div class="card mt-n13 z-index-3 pt-10 border-0">
            <div class="card-body p-0">
              <h2 class="text-dark lh-1625 text-center mb-2">Política Privacidade</h2>
              <br>
              <p class="mxw-751 text-justify mb-8 px-8">
                A sua privacidade é importante para nós. É política da Imobiliária Santos respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site www.ssantos.com.br que possuímos e operamos.
              </p>
              <p class="mxw-751 text-justify mb-8 px-8">
                Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado. Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
              </p>
              <p class="mxw-751 text-justify mb-8 px-8">
                Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.
              </p>
              <p class="mxw-751 text-justify mb-8 px-8">
                O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.
              </p>
              <p class="mxw-751 text-justify mb-8 px-8">
                Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.
              </p>
              <p class="mxw-751 text-justify mb-8 px-8">
                O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco.
              </p>

              <h2 class="text-dark lh-1625 text-center mb-2">Compromisso do Usuário</h2>
              <br>
              <p class="mxw-751 text-justify mb-8 px-8">
                O usuário se compromete a fazer uso adequado dos conteúdos e da informação que a Imobiliária Santos oferece no site e com caráter enunciativo, mas não limitativo:
              </p>
              <p class="mxw-751 text-justify mb-8 px-8">
                a) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;
              </p>
              <p class="mxw-751 text-justify mb-8 px-8">
                b) Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, casas de apostas legais, jogos de sorte e azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;
              </p>
              <p class="mxw-751 text-justify mb-8 px-8">
                c) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) da Imobiliária Santos, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.
              </p>

              <h2 class="text-dark lh-1625 text-center mb-2">Mais informações</h2>
              <br>
              <p class="mxw-751 text-justify mb-8 px-8">
                Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.
              </p>

            </div>
          </div>
        </div>
      </section>

    </main>

    <footer-layout />

    <svg-files />

</template>

<script>
import HeaderLayout from '@/layouts/Header.vue'
import FooterLayout from '@/layouts/Footer.vue'
import SvgFiles from '@/layouts/Svg.vue'

import { baseUrl } from '@/config.js'
import { initFunctions } from '../../public/js/theme'

export default {
  name: 'politica',
  components: {
    HeaderLayout,
    FooterLayout,
    SvgFiles
  },
  data() {
    return {
        baseUrl: baseUrl
    }
  },
  mounted() {
    initFunctions();
  },
  created() {
    document.title = 'Imobiliária Santos :: Política de Privacidade'
  }
};
</script>