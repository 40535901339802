<template>
    <header-layout />

    <main id="content">

      <section class="pt-2 pb-10 pb-lg-17 page-title bg-overlay bg-img-cover-center" style="background-image: url('images/BG5.jpg');">
        <div class="container">
          <h1 class="fs-22 fs-md-42 mb-0 text-white font-weight-normal text-center pt-17 pb-13 lh-15 px-lg-16" data-animate="fadeInDown">
            Entre em Contato
          </h1>
        </div>
      </section>

      <section>
        <div class="container">
          <div class="card border-0 mt-n13 z-index-3 pb-8 pt-10">
            <div class="card-body p-0">
              <h2 class="text-heading mb-2 fs-22 fs-md-32 text-center lh-16">Nós estamos sempre à disposição para ouvir você!</h2>
              <p class="text-center mxw-670 mb-8">
                Utilize o formulário abaixo para nos enviar uma mensagem.
                Você também pode ligar através dos telefones ou do Whatsapp.
              </p>
              <form class="mxw-751 px-md-5" @submit.prevent="checkForm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input type="text" placeholder="Seu nome" class="form-control form-control-lg border-0" name="name" id="nome" v-model="nome">
                      <div v-if="errors.nome" class="text-danger">{{ errors.nome }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input placeholder="Seu e-mail" class="form-control form-control-lg border-0" type="email" name="email" id="email" v-model="email">
                      <div v-if="errors.email" class="text-danger">{{ errors.email }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="text" placeholder="Seu telefone" name="phone" class="form-control form-control-lg border-0" id="phone" v-model="phone">
                      <div v-if="errors.phone" class="text-danger">{{ errors.phone }}</div>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-6">
                  <textarea class="form-control border-0" placeholder="Sua mensagem" name="mensagem" rows="5"  id="mensagem" v-model="mensagem"></textarea>
                    <div v-if="errors.mensagem" class="text-danger">{{ errors.mensagem }}</div>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-lg btn-primary px-9" :disabled="isDisabled">{{buttonText}}</button>
                </div>
              </form>
            </div>
          </div>
          <div class="row mb-12">
            <div class="col-md-12 mb-6 text-center">
              <div class="card border-0">
                <div class="media align-items-center">
                  <div class="media-body">
                    <span class="text-primary fs-40 lh-1 d-inline-block mr-3">
                        <svg class="icon icon-f1"><use xlink:href="#icon-f1"></use></svg>
                    </span>
                    <h4 class="fs-22 lh-15 mb-0 text-heading">Balneário Camboriú</h4>
                  </div>
                </div>
                <div class="card-body px-0 pt-3 pb-0">
                  <p class="card-text mb-0">
                    Rua 51 n.51 - Sala 02 - Centro
                  </p>
                    <a class="d-block font-weight-bold fs-15" style="letter-spacing: 4px;" href="tel:(47)33670826">(47) 3367-0826</a>
                    <a class="d-block font-weight-bold fs-15" style="letter-spacing: 4px;" href="tel:(47)33670139">(47) 3367-0139</a>
                    <a class="d-block font-weight-bold fs-15" style="letter-spacing: 4px;" href="https://api.whatsapp.com/send?phone=5547988012756&text=Ol%C3%A1%2C%20quero%20mais%20informa%C3%A7%C3%B5es!" target="_blank" title="Vamos conversar no Whatsapp!">
                        <figure class="flex-default">(47) 98801-2756 <img src="images/wpp-logo.svg" alt="Whatsapp" class="img mb-5"></figure>
                    </a>
                  <a href="mailto:ssantos@ssantos.com.br" class="d-block text-body hover-primary text-decoration-none">ssantos@ssantos.com.br</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div id="map" class="mapbox-gl map-point-animate"
         style="height: 550px"
         data-mapbox-access-token="pk.eyJ1IjoiaW1vYmlsaWFyaWFzc2FudG9zIiwiYSI6ImNrbXJqcGJtazA4amgydW80MTU0OXB0dnoifQ.rloxhho12g0UVwPTyZSeLg"
         data-mapbox-options='{"center":[-48.63288369424898, -26.987068999056383],"setLngLat":[-48.63288369424898, -26.987068999056383]}'
         data-mapbox-marker='[{"position":[-48.63288369424898, -26.987068999056383],"className":"marker","backgroundImage":"images/googlle-market-01.png","backgroundRepeat":"no-repeat","width":"32px","height":"40px"}]'></div>
      </section>
    </main>

    <footer-layout />

    <svg-files />

    <message-modal />

</template>

<script>
import HeaderLayout from '@/layouts/Header.vue'
import FooterLayout from '@/layouts/Footer.vue'
import SvgFiles from '@/layouts/Svg.vue'
import MessageModal from '@/layouts/MessageModal'

import { baseUrl } from '@/config.js'
import { initFunctions, initMapBox, showMessageModal } from '../../public/js/theme'

export default {
  name: 'contato',
  components: {
    HeaderLayout,
    FooterLayout,
    SvgFiles,
    MessageModal
  },
  methods: {
      checkForm: function (event) {
        this.errors = [];

        if (!this.nome) {
            this.errors.nome = 'O nome é obrigatório.';
        }
        if (!this.email) {
            this.errors.email = 'E-mail é obrigatório.';
        }
        if (!this.phone) {
            this.errors.phone = 'Telefone é obrigatório.';
        }
        if (!this.mensagem) {
            this.errors.mensagem = 'Mensagem é obrigatória.';
        }

        if (Object.keys(this.errors).length == 0) {
            let dados = JSON.stringify({
                nome: this.nome,
                email: this.email,
                phone: this.phone,
                mensagem: this.mensagem
            })

            this.disabled = false
            this.buttonText = "Enviando..."

            this.axios.post(baseUrl + "contato.php", dados, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {
                let res = response.data;
                if (response.status === 200 && res.status === 'ok') {

                    this.nome = this.email = this.phone = this.mensagem = '';
                    event.target.reset()
                    
                    showMessageModal('<h5 class="text-center">Seu contato foi enviado!</h5>')
                } else {
                    showMessageModal('<h5 class="text-center">Ocorreu algum problema ao enviar seu contato.<br>Tente novamente mais tarde.</h5>')
                }
                this.disabled = true
                this.buttonText = "Enviar"

            })
            .catch(error => {
                showMessageModal('<h5 class="text-center">Ocorreu um erro ao enviar seu contato.<br>'+error+'</h5>')
                //console.error("There was an error!", error);
            })
        }
      }
  },
  data() {
    return {
        errors: [],
        nome: null,
        email: null,
        phone: null,
        mensagem: null,
        disabled: true,
        buttonText: 'Enviar',
        baseUrl: baseUrl
    }
  },
  computed: {
    isDisabled: function() {
        return !this.disabled;
    }
  },
  mounted() {
    initFunctions();
    initMapBox();
  },
  created() {
    document.title = 'Imobiliária Santos :: Contato'
  }
}
</script>