import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag-next";
import { router } from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue3-cookies'

createApp(App).use(VueGtag, { property: { id: "G-40RRE9WTB1" }}).use(store).use(router).use(VueAxios, axios).use(VueCookies, {
    expireTimes: new Date(2099,8,27).toUTCString(),
    path: "/",
    domain: "",
    secure: false,
    sameSite: "None"
}).mount('#app')

