<template>
    <header-layout />

    <main id="content" v-for="imovel in oImovel(id)" :key="imovel.id">
        <section>
            <div class="container container-xxl">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb pt-6 pt-lg-2 lh-15 pb-5">
                        <li class="breadcrumb-item">
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="breadcrumb-item pointer" @click="back">
                            Imóveis
                        </li>
                        <li class="breadcrumb-item active">{{ imovel.nome }}</li>
                    </ol>
                </nav>
            </div>
            <div class="container-fluid">
                <div class="position-relative" data-animate="zoomIn">
                    <!--
                    <div class="position-absolute pos-fixed-top-right z-index-3">
                        <ul class="list-inline pt-4 pr-5">
                            <li class="list-inline-item">
                                <a href="javascript:window.print()" data-toggle="tooltip" title="Imprimir" class="d-flex align-items-center justify-content-center w-40px h-40 bg-white text-heading bg-hover-primary hover-white rounded-circle">
                                    <i class="far fa-print"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    -->
                    <div class="row galleries m-n1">
                        <div class="col-lg-6 p-1">
                            <div class="item item-size-4-3">
                                <div class="card p-0 hover-zoom-in">
                                    <a :href="baseUrl + imovel.fotos[0]" class="card-img"
                                        data-gtf-mfp="true"
                                        :data-gallery-id="imovel.id"
                                        :style="{ backgroundImage: 'url(' + baseUrl + imovel.fotos[0] + ')' }">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 p-1">
                            <div class="row m-n1">
                                <div class="col-md-6 p-1" v-for="(foto,index) in imovel.fotos" :key="index" v-show="index > 0 && index < 5">
                                    <div class="item item-size-4-3" v-show="index > 0 && index < 5" v-if="index > 0">
                                        <div class="card p-0 hover-zoom-in">
                                            <a :href="baseUrl + foto" class="card-img"
                                                data-gtf-mfp="true"
                                                :data-gallery-id="imovel.id"
                                                :style="{ backgroundImage: 'url(' + baseUrl + foto + ')' }">
                                                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center hover-image bg-dark-opacity-04" v-if="index == 4">
                                                    <p class="fs-48 font-weight-600 text-white lh-1 mb-4">+{{ imovel.fotos.length -5 }}</p>
                                                    <p class="fs-16 font-weight-bold text-white lh-1625 text-uppercase">Visualizar mais</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="primary-content pt-8">
            <div class="container">
                <div class="row">
                    <article class="col-lg-12 pr-xl-7">
                        <section class="pb-7 border-bottom">
                            <div class="d-sm-flex justify-content-sm-between">
                                <div>
                                    <h2 class="fs-35 font-weight-600 lh-15 text-heading">{{ imovel.nome }}</h2>
                                    <p class="fs-22 text-heading mb-2 text-secondary">REFERÊNCIA {{ imovel.id }}</p>
                                    <p class="mb-0"><i class="fal fa-map-marker-alt mr-2"></i>{{ imovel.cidade }}</p>
                                </div>
                                <div class="mt-2 text-lg-right">
                                    <div v-for="operacao in imovel.operacao" :key="operacao.operacao">
                                        <div v-if="operacao === 'Vender'">
                                            <p class="fs-22 text-primary font-weight-bold mb-0" v-if="operacao === 'Vender'">
                                                <span class="list-inline-item badge badge-secondary mr-3 pt-2 pb-2">Venda</span>
                                                {{ imovel.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                                            </p>
                                        </div>
                                        <div v-if="operacao === 'Alugar'">
                                            <p class="fs-22 text-primary font-weight-bold mb-0" v-if="inArray('Temporada', imovel.aluguel)">
                                                <span class="list-inline-item badge badge-primary mr-2 pt-2 pb-2">Aluguel Temporada</span>
                                                CONSULTE
                                            </p>
                                            <p class="fs-22 text-primary font-weight-bold mb-0" v-if="inArray('Mensal', imovel.aluguel)">
                                                <span class="list-inline-item badge badge-warning mr-2 pt-2 pb-2">Aluguel Anual</span>
                                                <span v-if="imovel.valor_aluguel > 0">
                                                    {{ imovel.valor_aluguel.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                                                </span>
                                                <span v-else>
                                                    CONSULTE
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div v-if="imovel.status === 'Reservado'">
                                    <span class="badge badge-danger text-dark fs-22 mt-2">RESERVADO</span>
                                    </div>
                                </div>

                            </div>
                            <div class="mt-2 text-lg-right">
                                <div class="flex-default" id="whatsapp"><a class="d-block text-gray font-weight-bold fs-15 hover-dark" :href="'https://api.whatsapp.com/send?phone=5547988012756&text=Quero mais informacoes sobre o imovel ' + imovel.nome + ' REF ' + imovel.id" target="_blank" title="Vamos conversar no Whatsapp!">(47) 98801-2756 <img src="/../images/wpp-logo.svg" alt="Whatsapp" class="img mb-1 shake"></a></div>
                                <div class="flex-default" id="whatsapp"><a class="d-block text-gray font-weight-bold fs-15 hover-dark" :href="'https://api.whatsapp.com/send?phone=5547988012029&text=Quero mais informacoes sobre o imovel ' + imovel.nome + ' REF ' + imovel.id" target="_blank" title="Vamos conversar no Whatsapp!">(47) 98801-2029 <img src="/../images/wpp-logo.svg" alt="Whatsapp" class="img shake"></a></div>
                            </div>
                            <h4 class="fs-22 text-heading mt-6 mb-2" v-show="imovel.descricao.length > 0">Descrição</h4>
                            <p class="mb-0 lh-214">{{ imovel.descricao }}</p>
                        </section>
                        <section class="pt-6 border-bottom" v-if="imovel.qtd_quartos>0 || imovel.qtd_banheiros>0 || parseInt(imovel.area_total) > 0 || parseInt(imovel.vagas_garagem) > 0">
                            <h4 class="fs-22 text-heading mb-6">Características</h4>
                            <div class="row">
                                <div class="col-lg-3 col-sm-4 mb-6" v-if="imovel.qtd_quartos>0">
                                    <div class="media">
                                        <div class="p-2 shadow-xxs-1 rounded-lg mr-2">
                                            <svg class="icon icon-bedroom fs-32 text-primary">
                                                <use xlink:href="#icon-bedroom"></use>
                                            </svg>
                                        </div>
                                        <div class="media-body">
                                            <h5 class="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Quartos</h5>
                                            <p class="mb-0 fs-13 font-weight-bold text-heading">{{ imovel.qtd_quartos }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-4 mb-6" v-if="imovel.qtd_banheiros>0">
                                    <div class="media">
                                        <div class="p-2 shadow-xxs-1 rounded-lg mr-2">
                                            <svg class="icon icon-sofa fs-32 text-primary">
                                                <use xlink:href="#icon-sofa"></use>
                                            </svg>
                                        </div>
                                        <div class="media-body">
                                            <h5 class="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Banheiros</h5>
                                            <p class="mb-0 fs-13 font-weight-bold text-heading">{{ imovel.qtd_banheiros }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-4 mb-6" v-if="parseInt(imovel.area_total) > 0">
                                    <div class="media">
                                        <div class="p-2 shadow-xxs-1 rounded-lg mr-2">
                                            <svg class="icon icon-price fs-32 text-primary">
                                                <use xlink:href="#icon-price"></use>
                                            </svg>
                                        </div>
                                        <div class="media-body">
                                            <h5 class="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">ÁREA</h5>
                                            <p class="mb-0 fs-13 font-weight-bold text-heading">{{ parseInt(imovel.area_total) }} m<sup>2</sup></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-4 mb-6" v-if="parseInt(imovel.vagas_garagem) > 0">
                                    <div class="media">
                                        <div class="p-2 shadow-xxs-1 rounded-lg mr-2">
                                            <svg class="icon icon-Garage fs-32 text-primary">
                                                <use xlink:href="#icon-Garage"></use>
                                            </svg>
                                        </div>
                                            <div class="media-body">
                                                <h5 class="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Garagem</h5>
                                                <p class="mb-0 fs-13 font-weight-bold text-heading">{{ parseInt(imovel.vagas_garagem) }}</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section class="pt-6 border-bottom pb-4">
                            <h4 class="fs-22 text-heading mb-4">Detalhes Adicionais</h4>
                            <div class="row">
                                <dl class="col-sm-6 mb-0 d-flex">
                                <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">Referência</dt>
                                <dd class="text-secondary">{{ imovel.id }}</dd>
                                </dl>
                                <dl class="col-sm-6 mb-0 d-flex">
                                    <!--
                                    <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">Valor</dt>
                                    <div v-if="imovel.valor>0">
                                        <dd>{{ imovel.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</dd>
                                    </div>
                                    <div v-else>
                                        <dd>CONSULTE</dd>
                                    </div>
                                    -->
                                </dl>
                                <dl class="col-sm-6 mb-0 d-flex">
                                <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">Tipo</dt>
                                <dd>{{ imovel.tipo_imovel }}</dd>
                                </dl>
                                <dl class="col-sm-6 mb-0 d-flex">
                                <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">Finalidade</dt>
                                <dd v-if="inArray('Alugar', imovel.operacao) && inArray('Temporada', imovel.aluguel)">Aluguel Temporada</dd>
                                <dd v-if="inArray('Alugar', imovel.operacao) && inArray('Anual', imovel.aluguel)">Aluguel Anual</dd>
                                <dd v-if="inArray('Vender',imovel.operacao)">Venda</dd>
                                </dl>
                                <dl v-if="imovel.qtd_quartos" class="col-sm-6 mb-0 d-flex">
                                <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">Quartos</dt>
                                <dd>{{ imovel.qtd_quartos }}</dd>
                                </dl>
                                <dl v-if="imovel.qtd_suites" class="col-sm-6 mb-0 d-flex">
                                <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">Suítes</dt>
                                <dd>{{ imovel.qtd_suites }}</dd>
                                </dl>
                                <dl v-if="imovel.qtd_camas" class="col-sm-6 mb-0 d-flex">
                                <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">Camas</dt>
                                <dd>{{ imovel.qtd_camas }}</dd>
                                </dl>
                                <dl v-if="imovel.qtd_banheiros" class="col-sm-6 mb-0 d-flex">
                                <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">Banheiros</dt>
                                <dd>{{ imovel.qtd_banheiros }}</dd>
                                </dl>
                                <dl v-if="imovel.qtd_cozinha" class="col-sm-6 mb-0 d-flex">
                                <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">Cozinha</dt>
                                <dd>{{ imovel.qtd_cozinha }}</dd>
                                </dl>
                                <dl v-if="imovel.qtd_sala" class="col-sm-6 mb-0 d-flex">
                                <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">Sala</dt>
                                <dd>{{ imovel.qtd_sala }}</dd>
                                </dl>
                                <dl v-if="imovel.qtd_area_servico" class="col-sm-6 mb-0 d-flex">
                                <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">Área de Serviço</dt>
                                <dd>{{ imovel.qtd_area_servico }}</dd>
                                </dl>
                                <dl v-if="imovel.qtd_dep_empregada" class="col-sm-6 mb-0 d-flex">
                                <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">Dependência de Empregada</dt>
                                <dd>{{ imovel.qtd_dep_empregada }}</dd>
                                </dl>
                                <dl v-if="imovel.qtd_pessoas" class="col-sm-6 mb-0 d-flex">
                                <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">Capacidade de pessoas</dt>
                                <dd>{{ imovel.qtd_pessoas }}</dd>
                                </dl>
                                <dl v-if="imovel.qtd_ar_condicionado" class="col-sm-6 mb-0 d-flex">
                                <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">Ar Condicionado</dt>
                                <dd>{{ imovel.qtd_ar_condicionado }}</dd>
                                </dl>
                                <dl v-if="imovel.qtd_tv" class="col-sm-6 mb-0 d-flex">
                                <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">TV</dt>
                                <dd>{{ imovel.qtd_tv }}</dd>
                                </dl>
                                <dl v-if="imovel.qtd_elevadores" class="col-sm-6 mb-0 d-flex">
                                <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">Elevadores</dt>
                                <dd>{{ imovel.qtd_elevadores }}</dd>
                                </dl>
                                <dl v-if="imovel.vagas_garagem" class="col-sm-6 mb-0 d-flex">
                                <dt class="w-160px fs-14 font-weight-500 text-heading pr-2">Garagens</dt>
                                <dd>{{ imovel.vagas_garagem }}</dd>
                                </dl>
                            </div>
                        </section>
                        <section class="pt-6 border-bottom pb-4" v-if="imovel.qtd_playground || imovel.qtd_poli || imovel.qtd_sala_gin || imovel.qtd_salao_festa || imovel.qtd_saunaclass || imovel.qtd_seguranca || imovel.mobiliado || imovel.wifi">
                            <h4 class="fs-22 text-heading mb-4">Conveniências</h4>
                            <ul class="list-unstyled mb-0 row no-gutters">
                                <li v-if="imovel.qtd_playground" class="col-sm-3 col-6 mb-2"><i class="far fa-check mr-2 text-primary"></i>Playground</li>
                                <li v-if="imovel.qtd_poli" class="col-sm-3 col-6 mb-2"><i class="far fa-check mr-2 text-primary"></i>Quadra Poli Esportiva</li>
                                <li v-if="imovel.qtd_sala_gin" class="col-sm-3 col-6 mb-2"><i class="far fa-check mr-2 text-primary"></i>Sala de Ginástica</li>
                                <li v-if="imovel.qtd_salao_festa" class="col-sm-3 col-6 mb-2"><i class="far fa-check mr-2 text-primary"></i>Salão de Festas</li>
                                <li v-if="imovel.qtd_saunaclass" class="col-sm-3 col-6 mb-2"><i class="far fa-check mr-2 text-primary"></i>Sauna</li>
                                <li v-if="imovel.qtd_seguranca" class="col-sm-3 col-6 mb-2"><i class="far fa-check mr-2 text-primary"></i>Segurança Interna</li>
                                <li v-if="imovel.mobiliado" class="col-sm-3 col-6 mb-2"><i class="far fa-check mr-2 text-primary"></i>Mobiliado</li>
                                <li v-if="imovel.wifi" class="col-sm-3 col-6 mb-2"><i class="far fa-check mr-2 text-primary"></i>Wifi</li>
                            </ul>
                        </section>
                        <section class="py-6 border-bottom" v-if="containsKey(imovel,'url_video') && imovel.url_video.length > 0">
                            <h4 class="fs-22 text-heading mb-6">Vídeo</h4>
                            <iframe class="w-100" height="430" :src=imovel.url_video :title=imovel.nome frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </section>
                        <section class="py-6 border-bottom">
                            <h4 class="fs-22 text-heading mb-6">Localização</h4>
                            <div class="position-relative" v-if="containsKey(imovel,'geo')">
                                <div id="map" class="mapbox-gl map-point-animate"
                                    data-mapbox-access-token="pk.eyJ1IjoiaW1vYmlsaWFyaWFzc2FudG9zIiwiYSI6ImNrbXJqcGJtazA4amgydW80MTU0OXB0dnoifQ.rloxhho12g0UVwPTyZSeLg"
                                    :data-mapbox-options="this.getGeo(imovel.geo)"
                                    :data-mapbox-marker="this.getMarker(imovel.geo)">
                                </div>
                                <p class="mb-0 p-3 bg-white shadow rounded-lg position-absolute pos-fixed-bottom mb-4 ml-4 lh-17 z-index-2">{{ imovel.cidade }} - {{ imovel.uf }}</p>
                            </div>
                            <div class="position-relative mb-4" v-else>
                                <p class="p-3 bg-white shadow rounded-lg pos-fixed-bottom ml-4 mr-4 lh-17 z-index-2">{{ imovel.cidade }} - {{ imovel.uf }}</p>
                            </div>
                        </section>
                    </article>

                </div>
            </div>
        </div>
    </main>

    <footer-layout />

    <svg-files />

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useRoute } from "vue-router";

import HeaderLayout from '@/layouts/Header.vue'
import FooterLayout from '@/layouts/Footer.vue'
import SvgFiles from '@/layouts/Svg.vue'

import { baseUrl } from '@/config.js'
import { initFunctions, initMapBox } from '../../public/js/theme'

export default {
  name: 'imovel',
  components: {
    HeaderLayout,
    FooterLayout,
    SvgFiles
  },
  computed: {
      ...mapGetters([
          "oImovel"
      ])
  },
  methods: {
    ...mapActions(["fetchImoveis"]),
    containsKey(obj, key ) {
        return Object.keys(obj).includes(key);
    },
    getGeo(geo) {
        return "{'center':["+ geo +"],'setgeo':["+ geo +"],'zoom':14}";
    },
    getMarker(geo) {
        return "[{'position':["+ geo +"],'className':'marker','backgroundImage':'"+ baseUrl +"images/googlle-market-01.png','backgroundRepeat':'no-repeat','width':'30px','height':'40px'}]";
    },
    back() {
        this.$router.go(-1)
    },
    inArray(needle, haystack) {
        var length = haystack.length;
        for(var i = 0; i < length; i++) {
            if(haystack[i] == needle) return true;
        }
        return false;

        //return inArray(needle, haystack);
    },
    aluguelTemporada(operacao) {
        //if (![null, 'Temporada'].includes(operacao.tipo) && operacao.valor > 0) return true
        if (operacao == 'Temporada' && operacao.valor > 0) return true
    }
  },
  data() {
    return {
        id: 0,
        baseUrl: baseUrl
    }
  },
  mounted() {
    //initFunctions()
    initMapBox();
  },
  created() {
    const route = useRoute()
    this.id = route.params.id

    document.title = 'Imobiliária Santos :: REF ' + this.id

    this.$store.dispatch('fetchImoveis').then(() => {
        initFunctions();
        initMapBox();
    })
  }
};

/*
function inArray(needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] == needle) return true;
    }
    return false;
}
*/
</script>
