<template>
    <header class="main-header navbar-light header-sticky header-sticky-smart header-mobile-lg">
      <div class="sticky-area">
        <div class="container container-xxl">
          <div class="d-flex align-items-center">

            <header-nav />

            <div class="ml-auto d-none d-xl-block">
              <div class="flex-default" id="whatsapp"><a class="d-block text-gray font-weight-bold fs-15 hover-dark" href="https://api.whatsapp.com/send?phone=5547988012756&text=Ol%C3%A1%2C%20quero%20mais%20informa%C3%A7%C3%B5es!" target="_blank" title="Vamos conversar no Whatsapp!">(47) 98801-2756 <img src="/../images/wpp-logo.svg" alt="Whatsapp" class="img mb-1 shake"></a></div>
              <div class="flex-default" id="whatsapp"><a class="d-block text-gray font-weight-bold fs-15 hover-dark" href="https://api.whatsapp.com/send?phone=5547988012029&text=Ol%C3%A1%2C%20quero%20mais%20informa%C3%A7%C3%B5es!" target="_blank" title="Vamos conversar no Whatsapp!">(47) 98801-2029 <img src="/../images/wpp-logo.svg" alt="Whatsapp" class="img shake"></a></div>
            </div>

          </div>
        </div>
      </div>
    </header>
</template>

<script>
import HeaderNav from '@/layouts/HeaderNav.vue'

export default {
  name: 'header1',
  components: {
    HeaderNav
  }
};
</script>