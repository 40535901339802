<template>
    <header-home-layout />

    <main id="content">
      <section class="d-flex flex-column">
        <div style="background-image: url('images/bg-home.jpg')" class="bg-cover d-flex align-items-center custom-vh-100">
          <div class="container pt-lg-15 py-8" data-animate="zoomIn" v-show="imoveisList.length>0">
            <p class="text-white fs-md-22 fs-18 font-weight-500 letter-spacing-367 mb-6 text-center text-uppercase">Vamos ajudar você!</p>
            <h2 class="text-white display-2 text-center mb-sm-13 mb-8">Encontre o seu imóvel</h2>
            <form class="property-search py-lg-0 z-index-2 position-relative d-none d-lg-block" @submit.stop.prevent="submitClick">
              <div class="row no-gutters">
                <div class="col-md-5 col-lg-4 col-xl-3">
                  <ul class="nav nav-pills property-search-status-tab">
                    <li class="nav-item bg-secondary rounded-top" role="presentation">
                      <a href="#" role="tab" aria-selected="true" class="nav-link btn shadow-none rounded-bottom-0 text-white text-btn-focus-secondary text-uppercase d-flex align-items-center fs-13 rounded-bottom-0 bg-active-white text-active-secondary letter-spacing-087 flex-md-1 px-4 py-2 active" data-toggle="pill" data-value="Alugar" v-on:click="changeFinalidade('Alugar')">
                        Alugar
                      </a>
                    </li>
                    <li class="nav-item bg-secondary rounded-top" role="presentation">
                      <a href="#" role="tab" aria-selected="true" class="nav-link btn shadow-none rounded-bottom-0 text-white text-btn-focus-secondary text-uppercase d-flex align-items-center fs-13 rounded-bottom-0 bg-active-white text-active-secondary letter-spacing-087 flex-md-1 px-4 py-2" data-toggle="pill" data-value="Vender" v-on:click="changeFinalidade('Vender')">
                        Comprar
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="bg-white px-6 rounded-bottom rounded-top-right pb-6 pb-lg-0">
                <div class="row align-items-center" id="accordion-4">
                  <div class="col-md-6 col-lg-3 col-xl-3 pt-6 pt-lg-0 order-1">
                    <label class="text-uppercase font-weight-500 letter-spacing-093 mb-1">Tipo de imóvel</label>
                    <select class="form-control bg-transparent border-bottom rounded-0 border-color-input" title="Selecione" data-style="p-0 h-24 lh-17 text-dark" name="categoria" v-model="categoria">
                      <option :value="null">Todos</option>
                      <option 
                        v-for="cat in categoriasList"
                        :key="cat">{{ cat }}</option>
                    </select>
                  </div>
                  <div class="col-md-6 col-lg-4 col-xl-5 pt-6 pt-lg-0 order-2">
                    <label class="text-uppercase font-weight-500 letter-spacing-093">Referência</label>
                    <div class="position-relative">
                      <input type="text" name="referencia" class="form-control bg-transparent shadow-none border-top-0 border-right-0 border-left-0 border-bottom rounded-0 h-24 lh-17 pl-0 pr-4 font-weight-600 border-color-input placeholder-muted" placeholder="Informe a referência aqui" v-model="referencia" @change="changeReferencia">
                      <i class="far fa-search position-absolute pos-fixed-right-center pr-0 fs-18 mt-n3"></i>
                    </div>
                  </div>
                  <div class="col-sm pr-lg-0 pt-6 pt-lg-0 order-3">
                    <a href="#advanced-search-filters-4" class="btn advanced-search btn-accent h-lg-100 w-100 shadow-none text-secondary rounded-0 fs-14 fs-sm-16 font-weight-600 text-left d-flex align-items-center collapsed" data-toggle="collapse" data-target="#advanced-search-filters-4" aria-expanded="true" aria-controls="advanced-search-filters-4">
                      Pesquisa Avançada
                    </a>
                  </div>
                  <div class="col-sm pt-6 pt-lg-0 order-sm-4 order-4">
                    <button type="submit" class="btn btn-primary shadow-none fs-16 font-weight-600 w-100 py-lg-2 lh-213">Pesquisar</button>
                  </div>
                  <div id="advanced-search-filters-4" class="col-12 pt-4 pb-sm-4 order-sm-5 order-5 collapse" data-parent="#accordion-4">
                    <div class="row">
                      <div class="col-sm-6 col-lg-3 pt-6">
                        <label class="text-uppercase font-weight-500 letter-spacing-093 mb-1">Quartos</label>
                        <select class="form-control bg-transparent border-bottom rounded-0 border-color-input" title="Todos os quartos" data-style="p-0 h-24 lh-17 text-dark" name="quartos" v-model="quartos">
                          <option :value="null">Todos os quartos</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                        </select>
                      </div>
                      <div class="col-sm-6 col-lg-3 pt-6">
                        <label class="text-uppercase font-weight-500 letter-spacing-093 mb-1">Banheiros</label>
                        <select class="form-control bg-transparent border-bottom rounded-0 border-color-input" title="Todos os banheiros" data-style="p-0 h-24 lh-17 text-dark" name="banheiros" v-model="banheiros">
                          <option :value="null">Todos os banheiros</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                        </select>
                      </div>
                      <div class="col-sm-6 col-lg-3 pt-12 d-flex">
                        <div class="w-100 ml-8 mr-8">
                            <Slider v-model="precovalue" enabled :min="preco.min" :max="preco.max" :step="preco.step" :format="preco.format" @change="changePreco" />
                        </div>
                      </div>
                      <div class="col-sm-6 col-lg-3 pt-12 d-flex">
                        <div class="w-100 ml-8 mr-8">
                            <Slider v-model="areavalue" enabled :min="area.min" :max="area.max" :step="area.step" :format="area.format" @change="changeArea" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <form class="property-search property-search-mobile d-lg-none z-index-2 position-relative bg-white rounded mx-md-10"  @submit.stop.prevent="submitClick">
              <div class="row align-items-lg-center" id="accordion-4-mobile">
                <div class="col-12">
                  <div class="form-group mb-0 position-relative">
                    <a href="#advanced-search-filters-4-mobile" class="text-secondary btn advanced-search shadow-none pr-3 pl-0 d-flex align-items-center position-absolute pos-fixed-left-center py-0 h-100 border-right collapsed" data-toggle="collapse" data-target="#advanced-search-filters-4-mobile" aria-expanded="true" aria-controls="advanced-search-filters-4-mobile">
                    </a>
                    <input type="text" class="form-control form-control-lg border shadow-none pr-9 pl-11 bg-white placeholder-muted" name="referencia" placeholder="Referência" v-model="referencia" @change="changeReferencia">
                    <button type="submit" class="btn position-absolute pos-fixed-right-center p-0 text-heading fs-20 px-3 shadow-none h-100 border-left">
                      <i class="far fa-search"></i>
                    </button>
                  </div>
                </div>
                <div id="advanced-search-filters-4-mobile" class="col-12 pt-2 px-7 collapse" data-parent="#accordion-4-mobile">
                  <div class="row mx-n2">
                    <div class="col-sm-6 pt-4 px-2">
                      <select class="form-control border shadow-none form-control-lg bg-transparent" title="Finalidade" data-style="btn-lg py-2 h-52 bg-transparent" name="finalidade" v-model="finalidade">
                        <option :value="null" disabled selected>Finalidade</option>
                        <option v-for="tp in finalidades" :value="tp.value" :key="tp.value">{{ tp.text }}</option>
                      </select>
                    </div>
                    <div class="col-sm-6 pt-4 px-2">
                      <select class="form-control border shadow-none form-control-lg bg-transparent" title="Tipo de imóvel" data-style="btn-lg py-2 h-52 bg-transparent" name="categoria" v-model="categoria">
                        <option :value="null" disabled selected>Tipo de imóvel</option>
                        <option :value="null">Todos</option>
                        <option 
                        v-for="cat in categoriasList"
                        :key="cat">{{ cat }}</option>
                      </select>
                    </div>
                    <div class="col-sm-6 pt-4 px-2">
                      <select class="form-control border shadow-none form-control-lg bg-transparent" title="Quartos" data-style="btn-lg py-2 h-52 bg-transparent" name="quartos" v-model="quartos">
                        <option :value="null" disabled selected>Quartos</option>
                        <option>Todos os quartos</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                      </select>
                    </div>
                    <div class="col-sm-6 pt-4 px-2">
                      <select class="form-control border shadow-none form-control-lg bg-transparent" title="Banheiros" data-style="btn-lg py-2 h-52 bg-transparent" name="banheiros" v-model="banheiros">
                        <option :value="null" disabled selected>Banheiros</option>
                        <option :value="null">Todos os banheiros</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                      </select>
                    </div>
                    <div class="col-md-6 pt-10 w-90">
                        <div class="w-100 ml-1 mr-2 pl-2 pr-2 text-center">
                            <Slider v-model="precovalue" enabled :min="preco.min" :max="preco.max" :step="preco.step" :format="preco.format" @change="changePreco" />
                        </div>
                    </div>
                    <div class="col-md-6 pt-10 w-90">
                        <div class="w-100 ml-1 mr-2 pl-2 pr-2 text-center">
                            <Slider v-model="areavalue" enabled :min="area.min" :max="area.max" :step="area.step" :format="area.format" @change="changeArea" />
                        </div>
                    </div>
                    <div class="col-sm pt-4 mb-6 pt-lg-0">
                        <button type="submit" class="btn btn-primary shadow-none fs-16 font-weight-600 w-100 py-lg-2 lh-213">Pesquisar</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="container pt-lg-15 py-8" data-animate="zoomIn" v-show="imoveisList.length===0">
            <p class="text-white fs-md-22 fs-18 font-weight-500 letter-spacing-367 mb-6 text-center text-uppercase">Estamos atualizando nossa lista de imóveis.</p>
            <h2 class="text-white display-2 text-center mb-sm-13 mb-8">Fale conosco através dos<br>telefones ou Whatsapp!</h2>
          </div>

        </div>
      </section>

      <destaques-layout />
      <categorias-layout />

    </main>

    <footer-layout />

    <svg-files />

</template>

<script>
/* eslintIgnore initFunctions */

import { mapGetters, mapActions } from "vuex";
import Slider from '@vueform/slider'

import HeaderHomeLayout from '@/layouts/HeaderHome.vue'
import FooterLayout from '@/layouts/Footer.vue'
import SvgFiles from '@/layouts/Svg.vue'

import { initFunctions } from '../../public/js/theme'

import DestaquesLayout from '@/components/Destaques.vue'
import CategoriasLayout from '@/components/Categorias.vue'

export default {
  name: 'home',
  components: {
    HeaderHomeLayout,
    FooterLayout,
    SvgFiles,
    DestaquesLayout,
    CategoriasLayout,
    Slider
  },
  computed: {
      ...mapGetters([
          "imoveisList",
          "categoriasList"
      ])
  },
  methods: {
    ...mapActions(["fetchImoveis"]),
    changePreco(val) {
        if (val != null) {
            this.ats.preco = val
        }
    },
    changeArea(val) {
        if (val != null) {
            this.ats.area = val
        }
    },
    changeFinalidade(val) {
        if (val != null) {
            this.finalidade = val
        }
    },
    submitClick(){
        this.$router.push({name: 'imoveis', params: {pfinalidade: this.finalidade, pcategoria: this.categoria, pquartos: this.quartos, pbanheiros: this.banheiros, pprecoini: this.ats.preco[0], pprecofim: this.ats.preco[1], pareaini: this.ats.area[0], pareafim: this.ats.area[1], preferencia: this.referencia}})
    }    
  },
  data() {
      return  { 
        ats: {finalidade: null, categoria: null, quartos: null, banheiros: null, preco: [], area: [], referencia: null},
        finalidades: [{ text:'Todas', value: null}, { text: 'Alugar', value: 'Alugar'}, { text: 'Comprar', value: 'Vender'}],
        finalidade: 'Alugar',
        categoria: null,
        quartos: null,
        banheiros: null,
        precovalue: [0, 10000000],
        preco: {
            disabled: false,
            min: 0,
            max: 10000000,
            step: 1000,
            format: {
                thousand: '.',
                prefix: 'R$ '
            }
        },
        areavalue: [0, 1000],
        area: {
            disabled: false,
            min: 0,
            max: 1000,
            step: 50,
            format: {
                thousand: '.',
                suffix: 'm²'
            }
        },
        referencia: null        
      }
  },
  mounted() {
    this.ats.preco = this.precovalue
    this.ats.area = this.areavalue
  },
  created() {
    document.title = 'Imobiliária Santos'

    this.$store.dispatch('fetchImoveis').then(() => {
        initFunctions();
    })
  }
};
</script>