const state = { 
    imoveis: [],
    destaquesList: [],
    categorias: [],
    lastUpdate: new Date().getTime(),
    atributos: [],
    limit: 9
};

const getters = { 
    imoveisList: state => state.imoveis,
    destaquesList: state => {
        return state.imoveis.filter(atributo => atributo.destaque == 1)
    },
    categoriasList: state => state.categorias,
    oImovel: state => (id) => {
        let imovel = []
        let operacoes = []
        for (var [key, imo] of Object.entries(state.imoveis)) {
            if (imo.id == id) {
                imovel = JSON.parse(JSON.stringify(state.imoveis[key]));
                operacoes.push({ operacao: imo.operacao, tipo:imo.aluguel, valor:imo.valor });
            }
        }

        Object.defineProperty(imovel, "operacoes", {value : operacoes,
            writable : true,
            enumerable : true,
            configurable : true})

        let ret = [];
        ret.push(imovel);
        return ret;

        //return state.imoveis.filter(imovel => imovel.id == id).splice(0,1)
    },
    imoveisListFilter: (state) => (atributos,order,page) => {
        let filtered = JSON.parse(JSON.stringify(state.imoveis));

        for (var [key, value] of Object.entries(atributos)) {

            if (key && value != null && value != 'null' && value != 'todas' && value != 'todos' && !Number.isNaN(value) && ((Array.isArray(value) && value[0] != null && value[1] != null) || ! Array.isArray(value))) {
                if ('finalidade' == key && value != 'Todas') {

                    if (value == 'Temporada') {
                        //filtered = filtered.filter(atributo => inArray('Alugar', atributo.operacao) && inArray('Temporada', atributo.aluguel))
                        //filtered = filtered.filter(atributo => atributo.operacao == 'Alugar' && atributo.aluguel == 'Temporada')

                        filtered = filtered.filter(atributo => (atributo.operacao[0] == 'Alugar' || atributo.operacao[1] == 'Alugar' || atributo.operacao[2] == 'Alugar' || atributo.operacao[3] == 'Alugar') && 
                        (atributo.aluguel[0] == 'Temporada' || atributo.aluguel[1] == 'Temporada' || atributo.aluguel[2] == 'Temporada' || atributo.aluguel[3] == 'Temporada'))

                    }
                    if (value == 'Anual') {
                        //filtered = filtered.filter(atributo => inArray('Alugar', atributo.operacao) && inArray('Mensal', atributo.aluguel))
                        //filtered = filtered.filter(atributo => atributo.operacao == 'Alugar' && atributo.aluguel == 'Mensal')

                        filtered = filtered.filter(atributo => (atributo.operacao[0] == 'Alugar' || atributo.operacao[1] == 'Alugar' || atributo.operacao[2] == 'Alugar' || atributo.operacao[3] == 'Alugar') && 
                        (atributo.aluguel[0] == 'Mensal' || atributo.aluguel[1] == 'Mensal' || atributo.aluguel[2] == 'Mensal' || atributo.aluguel[3] == 'Mensal'))
                    }
                    if (value == 'Vender') {
                        //filtered = filtered.filter(atributo => inArray('Vender', atributo.operacao))
                        filtered = filtered.filter(atributo => atributo.operacao[0] == 'Vender' || atributo.operacao[1] == 'Vender')
                    }
                }
                if ('categoria' == key && value != 'Todos') {
                    filtered = filtered.filter(atributo => atributo.tipo_imovel == value)
                }
                if ('quartos' == key) {
                    filtered = filtered.filter(atributo => atributo.qtd_quartos == value)
                }
                if ('banheiros' == key) {
                    filtered = filtered.filter(atributo => atributo.qtd_banheiros == value)
                }
                if ('preco' == key) {
                    filtered = filtered.filter(atributo => (atributo.valor >= parseFloat(value[0]) && atributo.valor <= parseFloat(value[1])) )
                }
                if ('area' == key) {
                    filtered = filtered.filter(atributo => (atributo.area_total >= parseFloat(value[0]) && atributo.area_total <= parseFloat(value[1])) || (atributo.area_util >= parseFloat(value[0]) && atributo.area_util <= parseFloat(value[1])) )
                }
                if ('referencia' == key) {
                    filtered = filtered.filter(atributo => atributo.id == value)
                }                
            }
        }

        if (order == 'refasc') {
            filtered.sort(function(a,b) {
                return a.id - b.id;
            });                
        }
        if (order == 'refdesc') {
            filtered.sort(function(a,b) {
                return b.id - a.id;
            });                
        }
        if (order == 'asc') {
            filtered.sort(function(a,b) {
                return a.valor - b.valor;
            });                
        }
        if (order == 'desc') {
            filtered.sort(function(a,b) {
                return b.valor - a.valor;
            });                
        }


        let total = filtered.length
        let ini = 0
        //let fim = state.limit

        if (page > 0) {
            ini = (page * state.limit)
            //fim = ((page + 1) * state.limit)
        }

        //filtered = filtered.splice(ini,fim)
        filtered = filtered.splice(ini,state.limit)

        return [total,filtered]
    }
};

const actions = { 
    async fetchImoveis({commit}){
        let now = new Date().getTime()
        if (state.imoveis.length === 0 || (now + (60*50)) > state.lastUpdate) {
            const response = await fetch("/../data.json");
            const data = await response.json();

            commit("setImoveis", data)
            
            const categorias = await groupByAuto(data,"tipo_imovel")

            commit("setCategorias", categorias)

            commit("setLastUpdate", new Date().getTime())
        }
    }
};

const mutations = { 
    setImoveis: (state, imoveis) => (
        state.imoveis = imoveis
    ),
    setCategorias: (state, categorias) => (
        state.categorias = categorias
    ),
    setLastUpdate: (state, lastUpdate) => (
        state.lastUpdate = lastUpdate
    ),
};

const imoveisModule = {
    state,
    getters,
    actions,
    mutations
}

function groupByAuto(data, key){
    var categorias = [];
    for(var i in data){
        var categoria = data[i][key];
        if(!categorias.includes(categoria) && categoria != 'ND') {
            categorias.push(categoria);
        }        
    }
    return categorias;
}

/*
function inArray(needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] == needle) return true;
    }
    return false;
}*/

export default imoveisModule;