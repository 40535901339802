<template>
    <section class="pt-lg-12 pb-lg-10 py-11" v-show="destaquesList.length>0">
        <div class="container container-xxl">
            <div class="row">
                <div class="col-md-6">
                    <h2 class="text-heading">Destaques</h2>
                    <span class="heading-divider"></span>
                    <p class="mb-6">Confira uma lista de imóveis selecionados especialmente para você!</p>
                </div>
                <div class="col-md-6 text-md-right">
                    <router-link to="/imoveis" class="btn fs-14 text-secondary btn-accent py-3 lh-15 px-7 mb-6 mb-lg-0">Veja todas os imóveis
                        <i class="far fa-long-arrow-right ml-1"></i>
                    </router-link>
                </div>
            </div>
            <div class="slick-slider slick-dots-mt-0 custom-arrow-spacing-30" data-slick-options='{"slidesToShow": 4, "autoplay":true,"dots":true,"responsive":[{"breakpoint": 1600,"settings": {"slidesToShow":3,"arrows":false}},{"breakpoint": 992,"settings": {"slidesToShow":2,"arrows":false}},{"breakpoint": 768,"settings": {"slidesToShow": 2,"arrows":false,"dots":true,"autoplay":true}},{"breakpoint": 576,"settings": {"slidesToShow": 1,"arrows":false,"dots":true,"autoplay":true}}]}'>
                <div class="box pb-7 pt-2"
                    v-for="imovel in destaquesList"
                    :key="imovel.id">
                    <div class="card" data-animate="zoomIn">
                        <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                            <router-link :to="{ name: 'imovel', params: { id: imovel.id } }" class="text-dark hover-primary">
                                <img :src="baseUrl + imovel.fotos[0]" :alt="imovel.nome" class="cropped">
                                <div class="card-img-overlay d-flex flex-column">
                                    <div v-if="imovel.operacao === 'Alugar'">
                                        <span class="badge mr-2 badge-primary" v-if="imovel.aluguel === 'Temporada'">Aluguel {{ imovel.aluguel }}</span>
                                        <span class="badge mr-2 badge-warning" v-if="imovel.aluguel === 'Anual'">Aluguel {{ imovel.aluguel }}</span>
                                    </div>
                                    <div v-else>
                                        <span class="badge mr-2 badge-secondary" v-if="imovel.operacao === 'Vender'">Venda</span>
                                    </div>

                            <ul class="list-inline mb-0 mt-auto hover-image">
                                <li class="list-inline-item mr-2" data-toggle="tooltip" :title="imovel.fotos.length + ' Imagens'">
                                  <a href="#" class="text-white hover-primary">
                                    <i class="far fa-images"></i><span class="pl-1">{{ imovel.fotos.length }}</span>
                                  </a>
                                </li>
                                <li class="list-inline-item" data-toggle="tooltip" title="Vídeo" v-if="imovel.url_video.length > 0">
                                  <a href="#" class="text-white hover-primary">
                                    <i class="far fa-play-circle"></i><span class="pl-1">1</span>
                                  </a>
                                </li>
                            </ul>

                                </div>
                            </router-link>
                        </div>
                        <div class="card-body pt-3">
                            <h2 class="card-title fs-16 lh-2 mb-0"><router-link :to="{ name: 'imovel', params: { id: imovel.id } }" class="text-dark hover-primary">{{ imovel.nome }}</router-link></h2>
                            <h3 class="fs-12 mb-1 text-secondary">REFERÊNCIA {{ imovel.id }}</h3>
                            <p class="card-text font-weight-500 text-gray-light mb-2">{{ imovel.cidade }}</p>
                            <ul class="list-inline d-flex mb-0 flex-wrap mr-n5">
                            <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5" v-if="imovel.qtd_quartos>0" data-toggle="tooltip" :title="imovel.qtd_quartos + ' Quartos'">
                                <svg class="icon icon-bedroom fs-18 text-primary mr-1">
                                    <use xlink:href="#icon-bedroom"></use>
                                </svg>
                                {{ imovel.qtd_quartos }}
                            </li>
                            <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5" v-if="imovel.qtd_banheiros>0" data-toggle="tooltip" :title="imovel.qtd_banheiros + ' Banheiros'">
                                <svg class="icon icon-shower fs-18 text-primary mr-1">
                                    <use xlink:href="#icon-shower"></use>
                                </svg>
                                {{ imovel.qtd_banheiros }}
                            </li>
                            <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5" v-if="parseInt(imovel.area_total) > 0" data-toggle="tooltip" title="Área">
                                <svg class="icon icon-square fs-18 text-primary mr-1">
                                <use xlink:href="#icon-square"></use>
                                </svg>
                                {{ parseInt(imovel.area_total) }} m<sup>2</sup>
                            </li>
                            <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5" v-if="parseInt(imovel.vagas_garagem) > 0" data-toggle="tooltip" :title="parseInt(imovel.vagas_garagem) + ' Garagem'">
                                <svg class="icon icon-Garage fs-18 text-primary mr-1">
                                    <use xlink:href="#icon-Garage"></use>
                                </svg>
                                {{ parseInt(imovel.vagas_garagem) }}
                            </li>
                            </ul>
                        </div>
                        <div class="card-footer bg-transparent d-flex justify-content-between align-items-center py-3">
                            <div v-if="aluguelTemporada(imovel)">
                                <p class="fs-17 font-weight-bold text-heading mb-0">{{ parseInt(imovel.valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</p>
                            </div>
                            <div v-else>
                                <p class="fs-17 font-weight-bold text-primary mb-0">CONSULTE</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>    
</template>

<script>
/* eslintIgnore initFunctions */

import { mapGetters } from "vuex";
import { initFunctions } from '../../public/js/theme'
import { baseUrl } from '@/config.js'

export default {
  name: 'Destaques',
  methods: {
        initialize: function() {
            initFunctions();
        },
        aluguelTemporada(imovel) {
            if (![null, 'Temporada'].includes(imovel.aluguel) && imovel.valor > 0) return true
        }
  },
  computed: {
      ...mapGetters([
          "destaquesList"
      ])
  },
  data() {
    return {
        baseUrl: baseUrl
    }
  },
  mounted() {
    setTimeout(function(){ initFunctions(); }, 600);
  }
};
</script>

<style>
.cropped {
    width: 358px !important;
    height: 358px;
    object-fit: cover;
}
</style>