<template>
    <header-layout />

    <main id="content">

      <section class="pt-2 pb-10 pb-lg-17 page-title bg-overlay bg-img-cover-center" style="background-image: url('images/BG2.jpg');">
        <div class="container">
          <h1 class="fs-22 fs-md-42 mb-0 text-white font-weight-normal text-center pt-17 pb-13 lh-15 px-lg-16" data-animate="fadeInDown">
            Sobre Nós
          </h1>
        </div>
      </section>

      <section>
        <div class="container">
          <div class="card border-0 mt-n13 z-index-3 pb-8 pt-10">
            <div class="card-body p-0">
                <h2 class="text-heading mb-2 fs-22 fs-md-32 text-center lh-16">Imobiliária Santos</h2>
                <p class="text-justify mxw-670 mb-8">
                    Estamos no mercado imobiliário de Balneário Camboriú em Santa Catarina há mais de 40 anos e prestamos serviços de administração de imóveis, locação e venda. Os anos de experiência continuam nos ensinando e buscamos ofertar um serviço de qualidade aos nossos clientes, cultivando sempre a boa relação com cada um deles. Trabalhamos de forma comprometida para garantir resultados e procuramos constantemente melhorar nossos serviços!
                </p>
                <p class="text-center mxw-670 mb-8">
                    Tradição e profissionalismo desde 1978!
                </p>

                <img class="mb-9 rounded" src="images/fachada.jpg" alt="Imobiliária Santos">

                <br><br>

                <h3 class="display-4">Missão</h3>
                <p class="ml-8 mr-8 pl-4 pr-4 fs-16 text-heading font-weight-500 lh-2 border-left border-4x border-primary my-6 text-justify">
                    Existimos para ajudar as pessoas a realizar sonhos e agregar valores.</p>

                <h3 class="display-4">Visão</h3>
                <p class="ml-8 mr-8 pl-4 pr-4 fs-16 text-heading font-weight-500 lh-2 border-left border-4x border-primary my-6 text-justify">
                    Ser reconhecida como empresa referência no ramo imobiliário pelos serviços prestados, atuando sempre com ética, humildade, transparência e comprometimento.</p>

                <h3 class="display-4">Valores</h3>
                <p class="ml-8 mr-8 pl-4 pr-4 fs-16 text-heading font-weight-500 lh-2 border-left border-4x border-primary my-6 text-justify"><span class="text-primary">Construir confiança</span><br>
                Acreditamos que a relação estabelecida com cada pessoa deve ser pautada na confiança. Valorizamos esse aspecto e cuidamos para manter esse elo de confiança em cada vínculo criado.</p>

                <p class="ml-8 mr-8 pl-4 pr-4 fs-16 text-heading font-weight-500 lh-2 border-left border-4x border-primary my-6 text-justify"><span class="text-primary">Ética</span><br>
                Trabalhamos de forma ética e queremos que nossos clientes se sintam seguros com nossos serviços prestados.</p>

                <p class="ml-8 mr-8 pl-4 pr-4 fs-16 text-heading font-weight-500 lh-2 border-left border-4x border-primary my-6 text-justify"><span class="text-primary">Honrar compromissos</span><br>
                Acreditamos que quando honramos nossos compromissos com nossos clientes, estamos de fato respeitando o valor de cada indivíduo.</p>

                <p class="ml-8 mr-8 pl-4 pr-4 fs-16 text-heading font-weight-500 lh-2 border-left border-4x border-primary my-6 text-justify"><span class="text-primary">Humildade</span><br>
                Tratamos todos os nossos clientes com o mesmo grau de importância e comprometimento, pois todos eles são importantes.</p>

            </div>
          </div>
        </div>
      </section>

    </main>

    <footer-layout />

    <svg-files />

</template>

<script>
import HeaderLayout from '@/layouts/Header.vue'
import FooterLayout from '@/layouts/Footer.vue'
import SvgFiles from '@/layouts/Svg.vue'

import { baseUrl } from '@/config.js'
import { initFunctions } from '../../public/js/theme'

export default {
  name: 'sobre',
  components: {
    HeaderLayout,
    FooterLayout,
    SvgFiles
  },
  data() {
    return {
        baseUrl: baseUrl
    }
  },
  mounted() {
    initFunctions();
  },
  created() {
    document.title = 'Imobiliária Santos :: Sobre Nós'
  }
};
</script>