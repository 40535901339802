<template>
    <div :class="{collapse: iagreeWindow.open}" class="position-fixed pos-fixed-bottom p-6 z-index-10 bg-secondary rounded-lg">
        <div class="list-inline-item">
            <div class="d-block text-lighter">
                <p style="text-align: left;">A Imobiliária Santos utiliza cookies e tecnologias semelhantes para possibilitar e aprimorar a sua experiência em nossas plataformas.<br>
                Para saber mais, acesse a nossa <router-link to="/politica-de-privacidade" class="lh-26 font-weight-500 hover-indigo">Política de Privacidade</router-link>.
                </p>
            </div>
            <div class="eupopup-buttons dark-blue-theme">
                <button type="button" class="btn btn-primary font-weight-bold fs-15" @click="closeIagree()">Estou ciente e aceito</button>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "mobilemenu",
    data() {
        return {
            iagreeWindow: {
                open: this.$cookies.get('Iagree')
            }
        }
    },
    methods: {
        closeIagree() {
            this.$cookies.set('Iagree',true);
            this.iagreeWindow.open = !this.iagreeWindow.open;
        }
    }
}
</script>