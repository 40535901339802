<template>
    <section>
        <div class="bg-gray-02 py-lg-13 pt-11 pb-6" v-show="imoveisList.length>0">
          <div class="container container-xxl">
            <div class="row">
              <div class="col-lg-4 pr-xl-13" data-animate="fadeInLeft">
                <h2 class="text-heading lh-1625">Explore <br>
                   por Tipo de Imóvel</h2>
                <span class="heading-divider"></span>
                <p class="mb-6">Confira nossa lista de categorias de imóveis</p>
                <router-link :to="{ name: 'imoveis'}" class="btn btn-lg text-secondary btn-accent">{{ imoveisList.length }} Imóveis Disponíveis
                  <i class="far fa-long-arrow-right ml-1"></i>
                </router-link>
              </div>
              <div class="col-lg-8" data-animate="fadeInRight">
                <div class="slick-slider arrow-haft-inner custom-arrow-xxl-hide mx-0" data-slick-options='{"slidesToShow": 4, "autoplay":true,"dots":false,"responsive":[{"breakpoint": 1200,"settings": {"slidesToShow":3,"arrows":false}},{"breakpoint": 992,"settings": {"slidesToShow":3,"arrows":false}},{"breakpoint": 768,"settings": {"slidesToShow": 3,"arrows":false,"autoplay":true}},{"breakpoint": 576,"settings": {"slidesToShow": 2,"arrows":false,"autoplay":true}}]}'>
                  <div class="box px-0 py-6"
                    v-for="categoria in categoriasList"
                    :key="categoria">
                    <router-link :to="{ name: 'imoveiscategoria', params: { pcategoria: categoria} }" class="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-hover-white text-decoration-none">
                      <img :src="categIcons[categoria]" class="card-img-top" :alt="categoria">
                      <div class="card-body px-0 pt-5 pb-0">
                        <h4 class="card-title fs-16 lh-2 text-dark mb-0">{{ categoria }}</h4>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>    
</template>

<script>
/* eslintIgnore initFunctions */

import { mapGetters } from "vuex";
import { initFunctions } from '../../public/js/theme'

export default {
  name: 'Categorias',
  methods: {
      initialize: function() {
          initFunctions();
      }
  },
  computed: {
      ...mapGetters([
          "imoveisList",
          "categoriasList"
      ])
  },
  created() {
    /*
    this.categorias = [
            'Apartamento Padrão',
            'Box/Garagem',
            'Casa Comercial',
            'Casa de Condomínio',
            'Casa de Vila',
            'Casa Padrão',
            'Chácara',
            'Conjunto Comercial/Sala',
            'Fazenda',
            'Flat',
            'Galpão/Depósito/Armazém',
            'Haras',
            'Hotel',
            'Indústria',
            'Kitchenette/Conjugados',
            'Loft',
            'Loja Shopping/ Ct Comercial',
            'Loja/Salão',
            'Loteamento/Condomínio',
            'Motel',
            'Pousada/Chalé',
            'Prédio Inteiro',
            'Sítio',
            'Studio',
            'Terreno Padrão'        
    ]
    */
  },
  data() {
    return {
        categIcons: {
            'Apartamento Padrão': 'images/verified.png',
            'Box/Garagem': 'images/verified.png',
            'Casa Comercial': 'images/architecture-and-city.png',
            'Casa de Condomínio': 'images/sofa.png',
            'Casa de Vila': 'images/sofa.png',
            'Casa Padrão': 'images/sofa.png',
            'Chácara': 'images/eco-house.png',
            'Conjunto Comercial/Sala': 'images/architecture-and-city.png',
            'Fazenda': 'images/eco-house.png',
            'Flat': 'images/verified.png',
            'Galpão/Depósito/Armazém': 'images/architecture-and-city.png',
            'Haras': 'images/eco-house.png',
            'Hotel': 'images/eco-house.png',
            'Indústria': 'images/architecture-and-city.png',
            'Kitchenette/Conjugados': 'images/architecture-and-city.png',
            'Loft': 'images/eco-house.png',
            'Loja Shopping/ Ct Comercial': 'images/architecture-and-city.png',
            'Loja/Salão': 'images/verified.png',
            'Loteamento/Condomínio': 'images/eco-house.png',
            'Motel': 'images/verified.png',
            'Pousada/Chalé': 'images/eco-house.png',
            'Prédio Inteiro': 'images/verified.png',
            'Sítio': 'images/eco-house.png',
            'Studio': 'images/architecture-and-city.png',
            'Terreno Padrão': 'images/eco-house.png'
        }
    }
  },
  mounted() {
    setTimeout(function(){ initFunctions(); }, 600);
  }
};
</script>